import { apiClient } from 'infra/api/config';
import { components } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';

interface OnSiteUserAllowanceTenant {
  /** 手当名 */
  name?: string | null;
  /** 手当額 */
  amount: number;
}
interface OnSiteUserEvaluableShopTenantUpsert {
  /** Format: uuid */
  shop_id?: string;
}

interface OnsiteUserRegisteredTenant {
  /** 名 */
  first_name: string;
  /** 姓 */
  last_name: string;
  /** ファーストネームかな */
  first_name_kana: string;
  /** ラストネームかな */
  last_name_kana: string;
  /** 性別 */
  sex: components['schemas']['SexEnum'];
  /** 電話番号 */
  phone_number: string;
  /**
   * メールアドレス
   * Format: email
   */
  email: string;
  /**
   * 入社した日付
   * Format: date
   */
  joined_at: string;
  /** マネジャーかどうか */
  is_manager: boolean;
  occupation_id: number;
  sub_occupation_id: number | null;
  grade_id: number;
  /** Format: uuid */
  boss_id?: string | null;
  /** Format: uuid */
  outside_manager_id?: string | null;
  /** Format: uuid */
  shop_id: string;
  salary: number;
  employment_status: string;
  allowances?: OnSiteUserAllowanceTenant[];
  is_evaluable_shops: boolean;
  evaluable_shops?: OnSiteUserEvaluableShopTenantUpsert[];
  /**
   * 退会日時
   * Format: date-time
   */
  unsubscribe_at?: string | null;
}

export const URL = '/tenant/my/user/onsite_users/';
export type Request = SnakeObjToCamelObj<OnsiteUserRegisteredTenant>;

export const postOnsiteuser = (request: Request) => {
  return apiClient.post(URL, request, {});
};
