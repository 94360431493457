import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { colorToRgba } from 'context/mvps/types/mvpCategory';
import { TwemojiIconFromCodePoint } from 'context/mvps/components/TwemojiIconFromCodePoint';
import { getMvpCategories } from 'context/mvps/api/get.mvpCategory';
import { LoadingBackdrop } from 'infra/components/UI/Overlays/LoadingBackdrop';
import Avatar from '@mui/material/Avatar';
import { components } from 'infra/api/schema';
import { Switch } from 'infra/components/UI/Forms/Switch';
import { useForm, UseFormReturn } from 'react-hook-form';
import { InformationIcon } from 'infra/components/UI/Icons/InformationIcon';
import { AlertIcon } from 'infra/components/UI/Icons/AlertIcon';
import { useState } from 'react';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import { useAtom } from 'jotai';
import { useDisplayNonFieldApiError } from 'infra/components/UI/Overlays/useDisplayNonFieldApiError';
import { Contents } from 'infra/components/UI/Overlays/Contents';
import { patchMvpCategorySetting } from '../api/patch.mvpCategorySetting';
import { EditMvpCategoryModal } from './EditMvpCategoryModal/Index';

export type CoinsListType = 'original' | 'canayell';

namespace Components {
  export const Description = ({ className }: { className?: string }) => {
    return (
      <div className={`text-base ${className}`}>
        <p className="mb-[10px]">
          MVPコインは
          <span className="font-bold">
            全10種類（オリジナルコイン1つ＋CANaYELLコイン9つ）
          </span>
          です。
        </p>
        <p className="mb-[15px]">各コインの名称は自由に変更可能です。</p>
        <p className="text-main-pink text-meta">
          ※
          名称変更の際は、各コインが持つ本来の意図や目的を損なわないようご注意ください。
        </p>
      </div>
    );
  };

  export const EnabledSwitch = ({
    useFormInstance,
    name,
    disabled,
    onChange,
  }: {
    useFormInstance: UseFormReturn<any>;
    name: string;
    disabled: boolean;
    onChange: (enabled: boolean) => void;
  }) => {
    const enabled = useFormInstance.watch(name);

    return (
      <div className="flex items-center">
        <Switch
          useForm={useFormInstance}
          name={name}
          disabled={disabled}
          onChange={onChange}
        />
        <div className="text-base font-bold ml-[8px]">
          {enabled ? '使用する' : '使用しない'}
        </div>
        <div className="px-[16px] py-[10px] flex items-center bg-grayscale-200 rounded-[10px] ml-[24px]">
          <div className="[&_path]:fill-main-blue mr-[8px]">
            <InformationIcon />
          </div>
          <div className="text-base font-medium">
            「使用しない」にするとアプリに表示されなくなります
          </div>
        </div>
      </div>
    );
  };

  export const DisabledCanayellCoinList = () => {
    return (
      <div className="flex justify-center items-center h-[234px] bg-grayscale-200 rounded-[10px]">
        <div className="flex flex-col justify-center items-center gap-[16px]">
          <AlertIcon />
          <div className="text-item-heading font-bold">
            CANaYELLコインは現在無効になっています
          </div>
          <div className="text-base">アプリには表示されません</div>
        </div>
      </div>
    );
  };

  export const CoinsList = ({
    title,
    data,
    className,
    refresh,
    type,
    showEnabledSwitch = false,
    defaultEnabled = true,
  }: {
    title: string;
    data: components['schemas']['MvpCategoryTenant'][];
    className?: string;
    refresh: () => void;
    type: CoinsListType;
    showEnabledSwitch?: boolean;
    defaultEnabled?: boolean;
  }) => {
    const [, displayMessage] = useAtom(displayMessageAtom);
    const displayNonFieldApiError = useDisplayNonFieldApiError();
    const [isLoading, setIsLoading] = useState(false);
    const switchName = 'enabled';

    const useFormInstance = useForm({
      defaultValues: {
        enabled: defaultEnabled ?? false,
      },
    });
    const enabled = useFormInstance.watch(switchName);

    const pathMvpCategorySetting = (canayellCoinEnabled: boolean) => {
      setIsLoading(true);
      patchMvpCategorySetting({
        canayellCoinEnabled,
      })
        .then(() => {
          displayMessage({
            text: 'CANaYELLコインの設定を更新しました。',
            isInterruption: false,
          });
          useFormInstance.setValue(switchName, canayellCoinEnabled);
        })
        .catch((error) => {
          useFormInstance.setValue(switchName, !canayellCoinEnabled);
          displayNonFieldApiError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    return (
      <div className={`text-base ${className}`}>
        {showEnabledSwitch ? (
          <div
            className={`w-full flex items-center border-b-2 border-grayscale-500 pb-[16px] mb-[20px] ${className}`}
          >
            <h2 className="font-bold text-item-heading mr-[24px]">{title}</h2>
            <EnabledSwitch
              useFormInstance={useFormInstance}
              name={switchName}
              disabled={isLoading}
              onChange={pathMvpCategorySetting}
            />
          </div>
        ) : (
          <h2
            className={`font-bold text-item-heading border-b-2 border-grayscale-500 pb-[16px] mb-[20px] ${className}`}
          >
            {title}
          </h2>
        )}
        <div
          className={`relative grid gap-[20px] ${
            isLoading || (showEnabledSwitch && !enabled) ? 'min-h-[234px]' : ''
          }`}
        >
          <Contents isLoading={isLoading}>
            {showEnabledSwitch && !enabled ? (
              <DisabledCanayellCoinList />
            ) : (
              data?.map((mvpCategory) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <MvpCategoryItem
                    key={mvpCategory.id}
                    mvpCategory={mvpCategory}
                    onComplated={() => {
                      refresh();
                    }}
                    type={type}
                  />
                );
              })
            )}
          </Contents>
        </div>
      </div>
    );
  };
}

export const MvpCategorySetting = () => {
  const [{ data, loading }, refresh] = getMvpCategories();

  if (loading) {
    return <LoadingBackdrop isShow />;
  }

  const OriginalCoins =
    data?.categories?.filter((mvpCategory) => {
      return mvpCategory.group === '__企業カスタマイズ__';
    }) ?? [];

  const CANaYELLCoins =
    data?.categories?.filter((mvpCategory) => {
      return (
        mvpCategory.name !== 'マイナスポイント' &&
        mvpCategory.group !== '__企業カスタマイズ__'
      );
    }) ?? [];

  return (
    <div>
      <PageTitle title="MVPコイン設定" breadcrumbs={[['ホーム', '/']]} />

      <div className="mt-[50px] p-main-section bg-grayscale-100">
        <Components.Description className="mb-[30px]" />

        <Components.CoinsList
          title="オリジナルコイン"
          data={OriginalCoins}
          refresh={refresh}
          type="original"
        />

        <Components.CoinsList
          showEnabledSwitch
          title="CANaYELLコイン"
          data={CANaYELLCoins}
          refresh={refresh}
          className="mt-[30px]"
          type="canayell"
          defaultEnabled={data?.canayellCoinEnabled ?? false}
        />
      </div>
    </div>
  );
};

const MvpCategoryItem = (props: {
  mvpCategory: any;
  onComplated: () => void;
  type: CoinsListType;
}) => {
  return (
    <div
      className="flex justify-between items-center px-[30px] py-[40px] rounded"
      style={{ backgroundColor: colorToRgba(props.mvpCategory.color, 0.1) }}
    >
      <div
        className="border-r-1 border-grayscale-600"
        style={{ width: `calc(100% - 240px)` }}
      >
        <div className="flex items-center">
          {props.mvpCategory.customIconImage === null ? (
            <div
              className="flex items-center justify-center bg-grayscale-100 w-[48px] h-[48px] rounded-full border-2"
              style={{ borderColor: props.mvpCategory.color }}
            >
              <TwemojiIconFromCodePoint codePoint={props.mvpCategory.icon} />
            </div>
          ) : (
            <div className="w-[48px] h-[48px]">
              <Avatar
                src={props.mvpCategory.customIconImage}
                alt=""
                sx={{ width: '100%', height: '100%' }}
              />
            </div>
          )}
          <div className="ml-[10px]">
            <p
              className="text-base font-bold mb-[10px]"
              style={{ color: props.mvpCategory.color }}
            >
              {props.mvpCategory.name}
            </p>
            {/* 改行をそのまま表示する  */}
            <p className="text-base break-all whitespace-pre-wrap">
              {props.mvpCategory.description}
            </p>
          </div>
        </div>
      </div>
      <EditMvpCategoryModal
        type={props.type}
        mvpCategory={props.mvpCategory}
        onComplated={() => {
          props.onComplated();
        }}
        render={(openModal) => (
          <BaseButton
            className="text-base font-bold w-[186px]"
            onClick={() => {
              openModal();
            }}
          >
            {props.type === 'original'
              ? 'オリジナルコインを設定'
              : 'コイン名称を変更'}
          </BaseButton>
        )}
      />
    </div>
  );
};
