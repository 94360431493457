import { apiClient, createOption, MY_TENANT_URL } from 'infra/api/config';
import { components } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';
import { ResponsePagination } from 'infra/api/type';

interface OnsiteUserFinalEvaluationCompletedTenant {
  id: number;
  onsite_user: components['schemas']['OnsiteUserTenantMini'];
  promotion_log: components['schemas']['PromotionLogTenantMini'] | null;
  evaluation_period: components['schemas']['EvaluationPeriodTenantMini'];
  basic_thinkings: components['schemas']['BasicThinkingTenantMini'][];
  score: number | null;
  previous_score: number | null;
  current_grade_name: string;
  next_grade_name: string;
  next_grade_salary: number;
  next_grade_salary_includeing_allowances: number;
  next_grade_wage_type: string;
}

export type ResponseObjectiveRecord =
  SnakeObjToCamelObj<OnsiteUserFinalEvaluationCompletedTenant> & {
    onsiteUser: {
      wealthResultProfile?: string;
    };
  };

export const fetchOnsiteUserFinalEvaluationCompleted = (
  evaluationPeriodId: number,
  userType: 'staff' | 'manager',
  limit = 9999,
  offset = 0
) => {
  const response = apiClient.get<ResponsePagination<ResponseObjectiveRecord>>(
    MY_TENANT_URL('promotion/onsite_user_final_evaluation_completed/'),
    {
      params: {
        limit,
        offset,
        evaluation_period_id: evaluationPeriodId,
        is_manager: userType === 'manager',
      },
    }
  );
  return response;
};
