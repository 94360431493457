import { ResponseObjectiveRecord } from 'context/objective/api/useFetchObjectiveRecords';
import { COLORS } from 'tailwind.config';
import ribbonIcon from 'context/mandalat/assets/ribbonIcon01.svg';
import { CommitmentSheetItem } from './CommitmentSheetItem';

export const CommitmentSheet = (props: {
  basicThinkings: ResponseObjectiveRecord['basicThinkings'];
  currentGrade: ResponseObjectiveRecord['currentGrade'];
  nextGrade: ResponseObjectiveRecord['nextGrade'];
}) => {
  const getCommitmentSheetItemByIndex = (index: number) => {
    const b = props.basicThinkings[index];
    if (b === undefined) {
      return <CommitmentSheetItem />;
    }
    const selectableData =
      b.basicThinkingMaster.selectableBasicThinkingMasters.find(
        (s) => s.grade === props.currentGrade
      ) || null;
    return (
      <CommitmentSheetItem
        key={b.id}
        color16={b.basicThinkingMaster.category.color}
        displayAttension={selectableData?.isMust ?? false}
        displayFlag={b.practicalThinkings.some((p) => p.isRecentGoal)}
        category={b.basicThinkingMaster.category.categoryMiddle}
      >
        <Content title={b.basicThinkingMaster.basicThinkingLabel} />
      </CommitmentSheetItem>
    );
  };

  return (
    <div className="grid grid-cols-3 grid-rows-3 gap-[1px]">
      {getCommitmentSheetItemByIndex(0)}
      {getCommitmentSheetItemByIndex(1)}
      {getCommitmentSheetItemByIndex(2)}
      {getCommitmentSheetItemByIndex(3)}
      <CommitmentSheetItem
        color16={COLORS.GRAY_600}
        displayAttension={false}
        displayFlag={false}
      >
        <div className="flex flex-col items-center gap-[4px]">
          <p className="relative">
            <img src={ribbonIcon} alt="リボンの画像" />
            <span
              className="
              absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
              text-mini font-bold text-grayscale-100 whitespace-nowrap leading-none
            "
            >
              めざす等級
            </span>
          </p>
          <p className="text-base font-bold text-center line-clamp-3">
            {props.nextGrade.name}
          </p>
        </div>
      </CommitmentSheetItem>

      {getCommitmentSheetItemByIndex(4)}
      {getCommitmentSheetItemByIndex(5)}
      {getCommitmentSheetItemByIndex(6)}
      {getCommitmentSheetItemByIndex(7)}
    </div>
  );
};

export const Content = (props: { title: string }) => {
  return (
    <div>
      <div className="text-note font-bold text-center line-clamp-3">
        {props.title}
      </div>
    </div>
  );
};
