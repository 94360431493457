import useAxios from 'axios-hooks';

import { components } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';

import { useNonFieldApiErrorCallback } from 'infra/components/UI/Overlays/useNonFieldApiErrorCallback';

export const URL = '/tenant/my/mvps/mvp_category/';
export type PagedSuccessResponse = SnakeObjToCamelObj<
  components['schemas']['MvpCategoriesTenant']
>;

/**
 * カテゴリ設定一覧の取得
 * @param useCache
 * @returns
 */
export const getMvpCategories = (useCache = false) => {
  const response = useAxios<PagedSuccessResponse>(
    {
      url: URL,
    },
    { useCache }
  );
  useNonFieldApiErrorCallback(response[0].error);

  return response;
};
