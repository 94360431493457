/* eslint-disable max-len */
export const DashboardMiniIcon = () => {
  return (
    <svg
      id="_-Product-Icons"
      data-name="🔍-Product-Icons"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="ic_fluent_data_usage_24_regular">
        <path
          id="_Color"
          data-name="🎨Color"
          d="M23.333,3A3.667,3.667,0,0,1,27,6.663V23.33A3.667,3.667,0,0,1,23.333,27H6.667A3.667,3.667,0,0,1,3,23.33V6.663A3.667,3.667,0,0,1,6.667,3Zm0,2H6.667A1.667,1.667,0,0,0,5,6.663V23.33A1.667,1.667,0,0,0,6.667,25H23.333A1.667,1.667,0,0,0,25,23.33V6.663A1.667,1.667,0,0,0,23.333,5Zm-14,6a1,1,0,0,1,.991.861l.009.135v8.675a1,1,0,0,1-1.991.135l-.009-.135V12A1,1,0,0,1,9.333,11ZM20.667,8.334a.991.991,0,0,1,.991.841l.009.132V20.7a1,1,0,0,1-1.991.132l-.009-.132V9.307A.987.987,0,0,1,20.667,8.334ZM14.967,15a.974.974,0,0,1,.976.828l.01.131L16,20.685a.977.977,0,0,1-1.943.15l-.01-.131L14,15.975A.972.972,0,0,1,14.967,15Z"
          transform="translate(-3 -2.997)"
          fill="#b9bcbf"
        />
      </g>
    </svg>
  );
};
