import { CircularProgress, Menu, MenuItem } from '@mui/material';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useFetchCorporation } from 'context/corporation/corporation/api/fetchCorporation';
import { getLoginInfo, useSessionLogin } from 'context/auth/api/login';
import { useAtom } from 'jotai';
import { logoutAtom } from 'context/auth/store/auth';
import { useNavigate } from 'react-router-dom';
import { useLogout } from 'context/auth/api/logout';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import { MenuDrawer } from './MenuDrawer';
import { Footer } from './Footer';
import { ExpandSidebar } from './icons/ExpandSidebar';
import { DashboardMiniIcon } from './icons/DashboardMiniIcon';
import { TalkWebMiniIcon } from './icons/TalkWebMiniIcon';
import { Tooltip } from './Tooltip';

/** 共通の画面レイアウト */
export const CommonLayout = (props: PropsWithChildren<{}>) => {
  const navigate = useNavigate();
  const [expandSidebarState, setExpandSidebarState] = useState(true);

  const [, displayMessage] = useAtom(displayMessageAtom);

  const [{ data: me, error, loading: loadingMyData }] = getLoginInfo();
  const [{ loading: sessionLoading }, sessionLogin] = useSessionLogin();

  useEffect(() => {
    const redirectLoginError = () => {
      console.warn('未ログインのためログイン画面へ遷移');
      displayMessage({ text: 'ログインしてください', isInterruption: false });
      navigate('/guest/login');
    };

    const errorStatus = error?.response?.status;

    (async () => {
      if (errorStatus === 404) {
        // ログインユーザー情報再取得
        if (localStorage.getItem('sessionLogin') === 'done') {
          localStorage.removeItem('sessionLogin');
          redirectLoginError();
          return;
        }
        try {
          await sessionLogin();
          window.location.reload();
        } catch {
          redirectLoginError();
        }
        return;
      }

      if (errorStatus !== 401 && errorStatus !== 403) return;

      // 認証エラー
      redirectLoginError();
    })();
  }, [error]);

  const [{ data: corporation, loading: loadingCorporation }] =
    useFetchCorporation(true);

  const [menuAnchorElement, setMenuAnchorElement] =
    useState<null | HTMLElement>(null);

  const [, logout] = useAtom(logoutAtom);
  const [, executeLogout] = useLogout();

  const onLogout = async () => {
    try {
      await executeLogout();
    } catch (logoutError: any) {
      console.warn(
        `ログアウトエラー ステータス：${logoutError?.response?.status}`
      );
    } finally {
      logout();
      navigate('/guest/login');
    }
  };

  const isLoading = loadingMyData || loadingCorporation || sessionLoading;

  return (
    // 横幅が大きくなりすぎると各アイテムの縦幅も大きくなりすぎるため
    <div className="h-screen flex bg-grayscale-400">
      <MenuDrawer expandSidebarState={expandSidebarState} />
      <div className="flex flex-col w-full relative">
        <div
          className="
              sticky top-0 z-50 bg-grayscale-100 text-grayscale-800
              box-shadow-main min-h-[60px] flex items-center
              pr-[30px]
            "
        >
          <p
            className="px-[10px] base-hover"
            onClick={() => {
              setExpandSidebarState(!expandSidebarState);
            }}
          >
            <ExpandSidebar />
          </p>
          <p className="text-base font-bold flex-grow">{corporation?.name}</p>

          {/* <div className="mr-[20px]">
            <HelpCircleIcon />
          </div> */}

          <div className="flex items-center space-x-[30px] mr-[30px]">
            <Tooltip
              content={{
                text: 'トーク',
                icon: <TalkWebMiniIcon />,
              }}
              onClick={() => {
                window.open(
                  process.env.REACT_APP_URL_TALK_WEB as string,
                  'talk_web'
                );
              }}
            />
            <Tooltip
              content={{
                text: 'ダッシュボード',
                icon: <DashboardMiniIcon />,
              }}
              onClick={() => {
                window.open(
                  process.env.REACT_APP_URL_DASHBOARD as string,
                  'dashboard'
                );
              }}
            />
          </div>

          <button
            type="button"
            aria-label="アカウントメニュー"
            onClick={(event) => setMenuAnchorElement(event.currentTarget)}
          >
            <p className="text-base text-main-blue font-bold">
              {me?.lastName} {me?.firstName}
            </p>
          </button>
          <Menu
            anchorEl={menuAnchorElement}
            open={menuAnchorElement !== null}
            onClose={() => setMenuAnchorElement(null)}
            // スクロールバーが消えるとposition:absoluteな要素がずれるので、スクロールバーを消さないように
            disableScrollLock
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={onLogout}>ログアウト</MenuItem>
          </Menu>
        </div>

        <div className="flex-grow bg-grayscale-400 overflow-y-scroll" id="main">
          {isLoading ? (
            <div className="w-full h-full grid place-items-center h-full">
              <CircularProgress size={60} color="primary" />
            </div>
          ) : (
            <div className="p-[32px] pb-[120px] min-w-0 max-w-[1184px] min-h-[calc(100vh-120px)]">
              {props.children}
            </div>
          )}
          <Footer />
        </div>
        <div
          id="injected-bottom-content-area"
          className="absolute bottom-0 w-full"
        />
      </div>
    </div>
  );
};
