import { FC, ReactNode, useRef, useState } from 'react';
import {
  arrow,
  useDismiss,
  useFocus,
  useFloating,
  useHover,
  useInteractions,
  useRole,
  FloatingArrow,
  offset,
} from '@floating-ui/react';

export type TooltipContent = {
  text: ReactNode;
  icon: ReactNode;
};

type TooltipContentTypes = {
  content: TooltipContent;
  onClick?: () => void;
};

export const Tooltip: FC<TooltipContentTypes> = ({ content, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom',
    middleware: [
      arrow({
        element: arrowRef,
      }),
      offset(10),
    ],
  });
  const hover = useHover(context);
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  return (
    <div className="relative group cursor-pointer w-[24px] h-[24px]">
      <button
        ref={refs.setReference}
        {...getReferenceProps()}
        className="base-hover"
        onClick={onClick}
        type="button"
      >
        {content.icon}
      </button>
      {isOpen && (
        <div
          ref={refs.setFloating}
          style={{
            ...floatingStyles,
          }}
          {...getFloatingProps()}
          className="text-grayscale-100 text-meta bg-grayscale-900 rounded p-[8px] w-max text-meta"
        >
          <FloatingArrow ref={arrowRef} context={context} tipRadius={2} />
          {content.text}
        </div>
      )}
    </div>
  );
};
