/* eslint-disable max-len */
export const TalkWebIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20.003"
      viewBox="0 0 20 20.003"
    >
      <g id="talk">
        <g
          id="_-Product-Icons"
          data-name="🔍-Product-Icons"
          transform="translate(-2 -2)"
        >
          <g id="ic_fluent_chat_24_regular">
            <path
              id="_-Color"
              data-name="🎨-Color"
              d="M12,2A10,10,0,1,1,7.413,20.888L3.587,21.955a1.25,1.25,0,0,1-1.54-1.54l1.068-3.823A10,10,0,0,1,12,2Zm0,1.5A8.5,8.5,0,0,0,4.573,16.137l.151.27L3.611,20.391,7.6,19.279l.27.15A8.5,8.5,0,1,0,12,3.5ZM8.75,13h4.5a.75.75,0,0,1,.1,1.493l-.1.007H8.75a.75.75,0,0,1-.1-1.493L8.75,13h0Zm0-3.5h6.5a.75.75,0,0,1,.1,1.493l-.1.007H8.75a.75.75,0,0,1-.1-1.493l.1-.007h0Z"
              fill="#038bf1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
