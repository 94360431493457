export const AlertIcon = ({ color = '#b9bcbf' }: { color?: string }) => {
  return (
    <svg
      id="ic_fluent_warning_24_regular"
      xmlns="http://www.w3.org/2000/svg"
      width="39.994"
      height="35.994"
      viewBox="0 0 39.994 35.994"
    >
      <path
        id="_-Color"
        data-name="🎨-Color"
        d="M19.809,3.049a4.569,4.569,0,0,1,5.946,1.44l.165.268L41.423,32a4.284,4.284,0,0,1,.563,2.12A4.427,4.427,0,0,1,37.8,38.484l-.308.01h-31a4.593,4.593,0,0,1-2.178-.548,4.319,4.319,0,0,1-1.9-5.669L2.556,32l15.5-27.242A4.438,4.438,0,0,1,19.809,3.049ZM38.8,33.412,23.3,6.17A1.52,1.52,0,0,0,21.261,5.6a1.487,1.487,0,0,0-.471.4l-.114.171L5.178,33.412a1.438,1.438,0,0,0,.585,1.983,1.527,1.527,0,0,0,.537.171l.189.012h31a1.479,1.479,0,0,0,1.5-1.459,1.425,1.425,0,0,0-.106-.54l-.081-.167L23.3,6.17ZM21.989,28.757a1.943,1.943,0,1,1-2,1.942A1.969,1.969,0,0,1,21.989,28.757Zm-.008-14.59a1.486,1.486,0,0,1,1.486,1.259l.014.2.007,8.754a1.5,1.5,0,0,1-2.983.2l-.014-.2-.007-8.754A1.479,1.479,0,0,1,21.981,14.168Z"
        transform="translate(-1.992 -2.5)"
        fill={color}
      />
    </svg>
  );
};
