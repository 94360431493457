// eslint-disable-next-line import/no-extraneous-dependencies
const plugin = require('tailwindcss/plugin');

// 共通の色を定数として定義
export const COLORS = {
  MAIN_RED: '#F44369',
  MAIN_GREEN: '#1FC8C2',
  MAIN_YELLOW: '#F9C824',
  MAIN_ORANGE: '#FA8F47',
  MAIN_PURPLE: '#7E7CF9',
  MAIN_PINK: '#FC506E',
  MAIN_BLUE: '#048CF2',
  MAIN_TEAL: '#5FC5C1',
  SUB_GREEN: '#2DEAA8',
  SUB_YELLOW: '#F8BC2C',
  SUB_PINK: '#ED71AD',
  LIGHT_RED: '#F4C8D1',
  LIGHT_PINK: '#F4D5E5',
  LIGHT_BLUE: '#BBDBF8',
  LIGHT_GREEN: '#CBF7E6',
  LIGHT_TEAL: '#C5ECEC',
  LIGHT_PURPLE: '#D8D7FA',
  LIGHT_ORANGE: '#F9DECA',
  LIGHT_YELLOW: '#FAEEC1',
  SOFT_YELLOW: '#FFF8DE',
  SOFT_BLUE: '#E5F3FE',
  SOFT_RED: '#FFE4E5',
  WARNING_RED: '#FF3B30',
  GRAY_100: '#FFFFFF',
  GRAY_200: '#FAFAFA',
  GRAY_300: '#F4F4F4',
  GRAY_400: '#F5F5F5',
  GRAY_500: '#E3E3E3',
  GRAY_600: '#B9BCBF',
  GRAY_700: '#757A80',
  GRAY_800: '#1A222C',
  GRAY_900: '#000000',
  FAINT_BLUE: '#EEF7FE',
};

// 共通のスタイルなどを定義
export const STYLES = {
  BOX_SHADOW: {
    MAIN: '0 0 10px rgba(0, 0, 0, 0.16)',
    HEADER: '0 3px 6px rgba(0, 0, 0, 0.1)',
    MODAL: '0 -8px 6px rgba(26, 34, 44, 0.05)',
  },
  Z_INDEX: {
    LOADING_BACK_DROP: 1301,
  },
  BORDER_RADIUS: {
    NONE: '0',
    SMALL: '3px',
    DEFAULT: '5px',
    LARGE: '9px',
    FULL: '9999px',
  },
  FONT_SIZE: {
    ATOM: '7px',
    MICRO: '8px',
    MINI: '9px',
    LABEL: '10px',
    NOTE: '11px',
    META: '12px',
    UI: '13px',
    BASE: '14px',
    BTN_SMALL: '10px',
    BTN_LARGE: '16px',
    BTN_CONFIRM: '18px',
    SECTION_HEADING: '20px',
    PAGE_HEADING: '24px',
    SCORE: '24px',
    RESULT: '32px',
    ITEM_HEADING: '16px',
  },
  WIDTH: {
    BTN_CELL: '60px',
    BTN_SMALL: '120px',
    BTN_MEDIUM: '150px',
    BTN_BASE: '200px',
    BTN_LARGE: '230px',
    BTN_CONFIRM: '280px',
  },
  OPACITY: {
    BASE: '0.7',
    MODAL: '0.6',
    SUSPEND: '0.2',
  },
  CURSOR: {
    POINTER: 'pointer',
    DEFAULT: 'default',
  },
  TRANSITIONDURATION: {
    BASE: '0.4s',
  },
  TRANSITIONTIMINGFUNCTION: {
    OUT_EXPO: 'cubic-bezier(0.16, 1, 0.3, 1)',
  },
};

/** @type {import('tailwindcss').Config} */
export default {
  content: ['./src/**/*.tsx', './src/**/*.ts', './public/index.html'],
  theme: {
    // ----- フォントファミリーの設定 -----
    // 注意: 以下のフォントファミリー設定はデザインガイドとしての目的で追加されています。
    // 実際のクラス名としては利用されていませんが、デザインの基準として参照してください。
    // @see ./src/index.css
    fontFamily: {
      body: [
        'SF UI Display', // 1. 最優先のフォント。
        '-apple-system', // 2. iOS & macOSでのデフォルトフォント (SF Pro & SF Pro Display)。
        'BlinkMacSystemFont', // 3. macOSでのデフォルトフォント (SF Pro)。
        'Roboto', // 4. Android OSの一般的なデフォルトフォント。
        'Noto Sans JP', // 5. Android & 多言語対応。主に日本語表示に。
        'Segoe UI', // 6. Windows OSでのデフォルトフォント。
        'Hiragino Kaku Gothic ProN', // 7. macOS & iOSの日本語表示用フォント。
        'YuGothic', // 8. Windows OSの日本語表示用フォント。
        'Meiryo', // 9. Windows OSの日本語表示用フォント。
        'sans-serif', // 10. 最後のフォールバックとしての一般的なサンセリフフォント。
      ],
    },
    fontSize: {
      atom: STYLES.FONT_SIZE.ATOM, // 最小のフォントサイズ
      micro: STYLES.FONT_SIZE.MICRO, // マンダラなどに使用するフォントサイズ
      mini: STYLES.FONT_SIZE.MINI, // マンダラなどに使用するフォントサイズ
      label: STYLES.FONT_SIZE.LABEL, // 『店舗MBO管理のステータスラベル』などのラベルで使用されているフォントサイズ
      note: STYLES.FONT_SIZE.NOTE, // 補足や説明を行うテキスト用のフォントサイズ
      meta: STYLES.FONT_SIZE.META, // 日付や『絞り込みボタン』などの文言（補足説明などの用途なので小さめ）に使用されているフォントサイズ
      ui: STYLES.FONT_SIZE.UI, // セレクトボックス内のオプションなどUI内で使用されているフォントサイズ
      base: STYLES.FONT_SIZE.BASE, // 管理画面の内で使用されている標準的なフォントサイズ
      'btn-small': STYLES.FONT_SIZE.BTN_SMALL, // ボタンのフォントサイズ
      'btn-large': STYLES.FONT_SIZE.BTN_LARGE, // ボタンのフォントサイズ
      'btn-confirm': STYLES.FONT_SIZE.BTN_CONFIRM,
      'section-heading': STYLES.FONT_SIZE.SECTION_HEADING, // モーダル内の見出しなどのフォントサイズ
      'page-heading': STYLES.FONT_SIZE.PAGE_HEADING, // 『HOME』や『業態一覧』などのページタイトルのフォントサイズ
      score: STYLES.FONT_SIZE.SCORE, // 最終評価などで使われるテキストのフォントサイズ
      result: STYLES.FONT_SIZE.RESULT, // スタッフ詳細、マンダラタブの評価得点などで使用されるフォントサイズ
      'item-heading': STYLES.FONT_SIZE.ITEM_HEADING, // メインカラムの詳細（白背景）で使用される見出しのフォントサイズ
    },
    colors: {
      'main-red': COLORS.MAIN_RED,
      'main-green': COLORS.MAIN_GREEN,
      'main-yellow': COLORS.MAIN_YELLOW,
      'main-orange': COLORS.MAIN_ORANGE,
      'main-purple': COLORS.MAIN_PURPLE,
      'main-pink': COLORS.MAIN_PINK,
      'main-blue': COLORS.MAIN_BLUE,
      'main-teal': COLORS.MAIN_TEAL,
      'sub-green': COLORS.SUB_GREEN,
      'sub-yellow': COLORS.SUB_YELLOW,
      'sub-pink': COLORS.SUB_PINK,
      'warning-red': COLORS.WARNING_RED,
      white: COLORS.GRAY_100,
      grayscale: {
        100: COLORS.GRAY_100,
        200: COLORS.GRAY_200,
        300: COLORS.GRAY_300,
        400: COLORS.GRAY_400,
        500: COLORS.GRAY_500,
        600: COLORS.GRAY_600,
        700: COLORS.GRAY_700,
        800: COLORS.GRAY_800,
        900: COLORS.GRAY_900,
      },
    },
    borderRadius: {
      none: STYLES.BORDER_RADIUS.NONE,
      small: STYLES.BORDER_RADIUS.SMALL,
      DEFAULT: STYLES.BORDER_RADIUS.DEFAULT,
      large: STYLES.BORDER_RADIUS.LARGE,
      full: STYLES.BORDER_RADIUS.FULL,
    },
    extend: {
      borderColor: {
        yellow: COLORS.MAIN_YELLOW,
        blue: COLORS.MAIN_BLUE,
        grayscale: {
          100: COLORS.GRAY_100,
          200: COLORS.GRAY_200,
          300: COLORS.GRAY_300,
          400: COLORS.GRAY_400,
          500: COLORS.GRAY_500,
          600: COLORS.GRAY_600,
          700: COLORS.GRAY_700,
          800: COLORS.GRAY_800,
          900: COLORS.GRAY_900,
        },
      },
      boxShadow: {
        main: STYLES.BOX_SHADOW.MAIN,
        header: STYLES.BOX_SHADOW.HEADER,
        modal: STYLES.BOX_SHADOW.MODAL,
      },
      textColor: {
        'anchor-base': COLORS.MAIN_BLUE,
      },
      opacity: {
        base: STYLES.OPACITY.BASE,
        modal: STYLES.OPACITY.MODAL,
        suspend: STYLES.OPACITY.SUSPEND,
      },
      transitionDuration: {
        base: '0.4s',
      },
      transitionTimingFunction: {
        'out-expo': 'cubic-bezier(0.16, 1, 0.3, 1)',
      },
      borderWidth: {
        1: '1px',
        2: '2px',
        3: '3px',
        4: '4px',
        5: '5px',
        6: '6px',
        7: '7px',
        8: '8px',
        9: '9px',
        10: '10px',
      },
      backgroundColor: {
        yellow: COLORS.MAIN_YELLOW,
        blue: COLORS.MAIN_BLUE,
        red: COLORS.MAIN_RED,
        pink: COLORS.MAIN_PINK,
        green: COLORS.MAIN_GREEN,
        teal: COLORS.MAIN_TEAL,
        orange: COLORS.MAIN_ORANGE,
        'light-red': COLORS.LIGHT_RED,
        'light-pink': COLORS.LIGHT_PINK,
        'light-blue': COLORS.LIGHT_BLUE,
        'light-green': COLORS.LIGHT_GREEN,
        'light-teal': COLORS.LIGHT_TEAL,
        'light-purple': COLORS.LIGHT_PURPLE,
        'light-orange': COLORS.LIGHT_ORANGE,
        'light-yellow': COLORS.LIGHT_YELLOW,
        'sub-green': COLORS.SUB_GREEN,
        'sub-yellow': COLORS.SUB_YELLOW,
        'sub-pink': COLORS.SUB_PINK,
        'soft-yellow': COLORS.SOFT_YELLOW,
        'soft-blue': COLORS.SOFT_BLUE,
        'soft-red': COLORS.SOFT_RED,
        white: COLORS.GRAY_100,
        attention: COLORS.SOFT_RED,
        grayscale: {
          100: COLORS.GRAY_100,
          200: COLORS.GRAY_200,
          300: COLORS.GRAY_300,
          400: COLORS.GRAY_400,
          500: COLORS.GRAY_500,
          600: COLORS.GRAY_600,
          700: COLORS.GRAY_700,
          800: COLORS.GRAY_800,
          900: COLORS.GRAY_900,
        },
        'faint-blue': COLORS.FAINT_BLUE,
      },
      padding: {
        wrapper: '30px 30px 40px 30px', // 当該箇所のキャプチャ画像: https://gyazo.com/b309dc45103950ad347225c47160a40f
      },
      spacing: {
        input: '18px 10px', // 当該箇所のキャプチャ画像: https://gyazo.com/3a1d9099ff606de68f53d2ba352cd517
        button: '14px 10px', // 当該箇所のキャプチャ画像: https://gyazo.com/45b8c22b492e9b1db22d9d266355942f
        'button-small': '9px 10px', // 当該箇所のキャプチャ画像: https://gyazo.com/b58e20e55f8556c822880c852016c44c
        'button-medium': '11px 10px', // 当該箇所のキャプチャ画像: https://prnt.sc/pGQ8YLHaTfb_
        select: '18px 24px', // 当該箇所のキャプチャ画像: https://gyazo.com/303bc013f1ccbad0fd19d45e06916bbf
        box: '30px', // 当該箇所のキャプチャ画像: https://gyazo.com/699c691cb525734241d567af3c63b624
        modal: '60px', // 当該箇所のキャプチャ画像: https://gyazo.com/310959f6a14b744530cccb9702276545
        column: '20px', // 当該箇所のキャプチャ画像: https://gyazo.com/f0de2f389ecbe80259682b0eaa8dce40
        tooltip: '20px', // 当該箇所のキャプチャ画像: https://gyazo.com/8d0682862526c85b8e5d764020dbd58a
        label: '4px 8px', // 当該箇所のキャプチャ画像: https://gyazo.com/4fbea891863668b480d4e5e7a3f0a9e4
        warning: '30px 22px', // 当該箇所のキャプチャ画像: https://gyazo.com/597e870cde94176296ed66565e8fe42a
        'main-section': '40px', // 当該箇所のキャプチャ画像: https://gyazo.com/edd542d4135119523239ae77eeee572e
      },
      width: {
        'btn-cell': STYLES.WIDTH.BTN_CELL,
        'btn-small': STYLES.WIDTH.BTN_SMALL,
        'btn-medium': STYLES.WIDTH.BTN_MEDIUM,
        'btn-base': STYLES.WIDTH.BTN_BASE,
        'btn-large': STYLES.WIDTH.BTN_LARGE,
        'btn-confirm': STYLES.WIDTH.BTN_CONFIRM,
      },
      letterSpacing: {
        'short-text': '0.2em',
        date: '0.05em',
      },
      lineHeight: {
        tight: '1.3',
        readable: '1.75',
        expanded: '2',
      },
      backgroundImage: () => ({
        'gradient-blue-green': 'linear-gradient(45deg, #048CF2, #2DEAA8)', // 当該箇所のキャプチャ画像: https://gyazo.com/68d225ee461a3255ddadca4fcedb6cc0
        'gradient-トレーダー': 'linear-gradient(45deg, #F9C82433, #F8BC2D33)',
        'gradient-アキュムレーター': 'linear-gradient(45deg, #757A8033, #F9C82433)',
        'gradient-ロード': 'linear-gradient(45deg, #757A8033, #B9BCBF33)',
        'gradient-サポーター': 'linear-gradient(0deg, #F4436933, #FA8F4733 )',
        'gradient-ディールメーカー': 'linear-gradient(45deg, #F8BC2D33, #FA8F4733)',
        'gradient-メカニック': 'linear-gradient(45deg, #757A8033, #21C8C233)',
        'gradient-スター': 'linear-gradient(45deg, #F4436933, #21C8C233)',
        'gradient-クリエイター': 'linear-gradient(45deg, #2DEAA833, #2DEAA833)',
      }),
    },
  },
  plugins: [
    plugin(({ addUtilities }) => {
      const newUtilities = {
        '.base-hover': {
          cursor: STYLES.CURSOR.POINTER,
          transitionDuration: STYLES.TRANSITIONDURATION.BASE,
          transitionTimingFunction: STYLES.TRANSITIONTIMINGFUNCTION.OUT_EXPO,
          '&:hover': {
            opacity: STYLES.OPACITY.BASE,
          },
        },
        '.bg-selectbox': {
          cursor: STYLES.CURSOR.POINTER,
          '&:nth-child(odd)': {
            backgroundColor: COLORS.GRAY_100,
          },
          '&:nth-child(even)': {
            backgroundColor: COLORS.SOFT_BLUE,
          },
        },
        '.pulldown-hover': {
          cursor: STYLES.CURSOR.POINTER,
          '&:hover': {
            backgroundColor: COLORS.MAIN_BLUE,
            color: COLORS.GRAY_100,
          },
        },
        '.select-hover': {
          cursor: STYLES.CURSOR.POINTER,
          '&:hover': {
            borderColor: COLORS.GRAY_700,
          },
        },
        '.base-scrollbar::-webkit-scrollbar': {
          width: '8px',
        },
        '.base-scrollbar::-webkit-scrollbar-track': {
          backgroundColor: '#fff',
        },
        '.base-scrollbar::-webkit-scrollbar-thumb': {
          background: COLORS.GRAY_600,
          borderRadius: '10px',
          border: '4px solid transparent',
          backgroundClip: 'content-box',
        },
        '.base-scrollbar::-webkit-scrollbar-thumb:hover': {
          background: COLORS.GRAY_700,
          borderRadius: '10px',
          border: '4px solid transparent',
          backgroundClip: 'content-box',
        },
        '.hidden-scrollbar': {
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        },
        '.no-selection-bg::selection': {
          backgroundColor: 'transparent',
        },
        '.no-selection-bg::-moz-selection': {
          // Firefox用の設定
          backgroundColor: 'transparent',
        },
        '.fixed-column': {
          position: 'sticky',
          right: '0',
          zIndex: '1',
          display: 'block!important',
          borderLeft: 'none!important',
          borderTop: 'none!important',
        },
      };
      addUtilities(newUtilities, ['responsive', 'hover']);
    }),
  ],
};
