/* eslint-disable max-len */
export const TalkWebMiniIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="talk">
        <g id="_-Product-Icons" data-name="🔍-Product-Icons">
          <g id="ic_fluent_chat_24_regular">
            <path
              id="_-Color"
              data-name="🎨-Color"
              d="M14,2A12,12,0,1,1,8.5,24.664L3.9,25.944A1.5,1.5,0,0,1,2.056,24.1l1.281-4.587A12,12,0,0,1,14,2Zm0,1.8A10.2,10.2,0,0,0,5.088,18.963l.181.324L3.933,24.068l4.784-1.334.323.18A10.2,10.2,0,1,0,14,3.8ZM10.1,15.2h5.4a.9.9,0,0,1,.122,1.792L15.5,17H10.1a.9.9,0,0,1-.122-1.792L10.1,15.2h0Zm0-4.2h7.805a.9.9,0,0,1,.122,1.792l-.122.008H10.1a.9.9,0,0,1-.122-1.792L10.1,11h0Z"
              transform="translate(-2 -2)"
              fill="#bbb"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
