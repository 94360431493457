import { useId } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ErrorMessage } from './ErrorMessage';

type Props = {
  useForm: UseFormReturn<any>;
  name: string;
  disabled?: boolean;
  onClick?: () => void;
  onChange?: (checked: boolean) => void;
};

export const Switch = (props: Props) => {
  const id = useId();
  return (
    <div className="leading-none">
      <label
        className={`relative inline-flex items-center
          ${props.disabled ? 'cursor-default' : 'cursor-pointer'}
        `}
        htmlFor={id}
      >
        <input
          type="checkbox"
          {...props.useForm.register(props.name)}
          onClick={props.onClick}
          onChange={(e) => {
            props.onChange?.(e.target.checked);
          }}
          className="sr-only peer"
          id={id}
          disabled={props.disabled ?? false}
        />
        <div
          className="
          w-[50px] h-[30px] bg-grayscale-500 rounded-full peer
          peer-focus:outline-none
          rtl:peer-checked:after:-translate-x-full peer-checked:after:border-grayscale-100
          after:content-[''] after:absolute after:top-[3px] after:start-[4px] after:bg-grayscale-100
          after:rounded-full after:h-[24px] after:w-[24px] after:transition-all
          peer-checked:bg-main-blue peer-checked:after:translate-x-[19px]
        "
          style={{
            backgroundColor: props.disabled ? '#78788029' : '',
          }}
        />
      </label>

      {!!props.useForm.formState.errors[props.name]?.message && (
        <ErrorMessage
          message={props.useForm.formState.errors[
            props.name
          ]?.message?.toString()}
        />
      )}
    </div>
  );
};
