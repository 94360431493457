/* eslint-disable max-len */
export const TalkWebFillIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20.003"
      viewBox="0 0 20 20.003"
    >
      <g id="talk">
        <g
          id="_-Product-Icons"
          data-name="🔍-Product-Icons"
          transform="translate(-2 -2)"
        >
          <g id="ic_fluent_chat_24_filled">
            <path
              id="_-Color"
              data-name="🎨-Color"
              d="M12,2A10,10,0,1,1,7.356,20.858l-4.29,1.117a.85.85,0,0,1-1.037-1.037L3.145,16.65A10,10,0,0,1,12,2Zm1.252,11H8.75l-.1.007a.75.75,0,0,0,0,1.486l.1.007h4.5l.1-.007a.75.75,0,0,0,0-1.486Zm2-3.5H8.75l-.1.007a.75.75,0,0,0,0,1.486l.1.007h6.5l.1-.007a.75.75,0,0,0,0-1.486Z"
              fill="#048cf2"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
