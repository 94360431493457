import { apiClient } from 'infra/api/config';
import { paths, components } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';

export const URL = '/tenant/my/mvps/mvp_category_setting/';

export type Request = SnakeObjToCamelObj<
  components['schemas']['PatchedMvpCategorySetting']
>;
export type SuccessResponse = SnakeObjToCamelObj<
  paths[typeof URL]['patch']['responses'][200]['content']['application/json']
>;

/**
 * 自企業が管理するMVPカテゴリの設定に関するエンドポイントです
 */
export const patchMvpCategorySetting = async (
  request: Request
): Promise<SuccessResponse> => {
  return apiClient.patch(URL, request);
};
